import styled, { css } from 'styled-components'

export const LoaderData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: ${({ theme }) => theme.layers.loaderData};
`

export const Spinner = styled.div`
  width: 10em;
  height: 10em;
  position: relative;
  margin: 60px auto;
  font-size: 10px;
  border-radius: 50%;
  border-top: 1.1em solid ${({ theme }) => theme.colors.secondary.light4};
  border-right: 1.1em solid ${({ theme }) => theme.colors.secondary.light4};
  border-bottom: 1.1em solid ${({ theme }) => theme.colors.secondary.light4};
  border-left: 1.1em solid ${({ theme }) => theme.colors.secondary.light1};
  text-indent: -9999em;
  opacity: 1;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  &::after {
    width: 10em;
    height: 10em;
    border-radius: 50%;
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
