import React, { useEffect, useMemo } from 'react'

import * as S from './styles'

const LoaderData = ({ when }) => {
  useEffect(() => {
    if (when) {
      document.body.classList.add('scroll-lock')
    } else {
      document.body.classList.remove('scroll-lock')
    }
  }, [when])

  if (when) {
    return (
      <S.LoaderData>
        <S.Spinner />
      </S.LoaderData>
    )
  }

  return null
}

export default LoaderData
