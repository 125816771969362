import React from 'react'
import Link from 'next/link'

import styled from 'styled-components'

import { Button } from '@/atoms'
import { Paragraph } from '@/atoms/Typography'

const Wrapper = styled.div`
  margin: 5px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.primary.light5};
  border: 2px solid ${({ theme }) => theme.colors.primary.default};
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
`

const PreviewBadge = () => {
  return (
    <Wrapper>
      <Paragraph
        content='You are in preview mode'
        bold
      />
      <Link
        href={`/api/disable-preview`}
        target='_blank'
        prefetch={false}
      >
        <Button
          label='Disable preview'
          fifth
          inheritedColorScheme='white'
        />
      </Link>
    </Wrapper>
  )
}

export default PreviewBadge
