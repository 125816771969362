import Head from 'next/head'

import { useRouter } from 'next/router'

import { stripTags } from '@utils/stripTags'

const Metadata = ({
  metaTitle,
  metaDescription,
  keywords,
  metaRobots,
  structuredData,
  metaViewport,
  canonicalURL,
  metaImage,
  metaSocial,
}) => {
  const router = useRouter()

  const canonicalBase =
    process.env.NEXT_PUBLIC_SITE_URL.replace(/\/$/, '') + router.asPath

  const title = `${!!metaTitle ? stripTags(metaTitle) : ''} | Arca Vita`
  const description = metaDescription ? stripTags(metaDescription) : ''

  const getImage = (image, imgSize) => {
    if (!!image) {
      const { formats, url } = image
      let defaultImg
      if (formats[imgSize]) {
        defaultImg = formats[imgSize]
      } else {
        const priority = ['large', 'medium', 'small']
        priority.forEach((format) => {
          if (formats && formats[format]) {
            defaultImg = formats[format]
          }
        })
      }
      return defaultImg || { url }
    }
    return metaImage ? getImage(metaImage) : null
  }

  const socials = metaSocial
    ? metaSocial.reduce((acc, curr) => {
        const image =
          curr.image && curr.image.data ? curr.image.data.attributes : null
        acc[curr.socialNetwork.toLowerCase()] = {
          ...curr,
          imageSmall: getImage(image, 'small'),
          imageThumbnail: getImage(image, 'thumbnail'),
        }
        return acc
      }, {})
    : {}

  return (
    <Head>
      <title>{title}</title>
      <meta
        name='description'
        content={description}
      />
      {keywords ? (
        <meta
          name='keywords'
          content={keywords}
        />
      ) : null}
      <meta
        name='viewport'
        content={
          metaViewport
            ? metaViewport
            : 'width=device-width, initial-scale=1, viewport-fit=cover'
        }
      />
      {metaRobots ? (
        <meta
          name='robots'
          content={metaRobots}
        />
      ) : null}

      {!!canonicalURL ? (
        <link
          rel='canonical'
          href={canonicalURL}
        />
      ) : (
        <link
          rel='canonical'
          href={canonicalBase}
        />
      )}

      {socials?.facebook ? (
        <>
          <meta
            property='og:type'
            content='website'
          />
          {!!canonicalURL && (
            <meta
              property='og:url'
              content={canonicalURL}
            />
          )}
          <meta
            property='og:title'
            content={`${socials.facebook.title} | Arca Vita`}
          />
          <meta
            property='og:description'
            content={socials.facebook.description}
          />
          <meta
            name='og:site_name'
            content='Arca Vita'
          />
          {socials.facebook.imageSmall && (
            <meta
              property='og:image'
              content={`/_next/image?url=${encodeURI(
                `${socials.facebook.imageSmall.url}&w=640&q=75`
              )}`}
            />
          )}
          {socials.facebook.imageSmall && (
            <meta
              property='og:image:width'
              content={socials.facebook.imageSmall.width}
            />
          )}
          {socials.facebook.imageSmall && (
            <meta
              property='og:image:height'
              content={socials.facebook.imageSmall.height}
            />
          )}
          {socials.facebook.imageSmall && (
            <meta
              property='og:image:type'
              content={socials.facebook.imageSmall.mime}
            />
          )}
        </>
      ) : null}

      {socials?.twitter ? (
        <>
          <meta
            name='twitter:card'
            content='summary_large_image'
          />
          <meta
            property='twitter:title'
            content={socials.twitter.title}
          />
          <meta
            property='twitter:description'
            content={socials.twitter.description}
          />
          {socials.twitter.imageSmall && (
            <meta
              property='twitter:image'
              content={`/_next/image?url=${encodeURI(
                `${socials.twitter.imageSmall.url}&w=640&q=75`
              )}`}
            />
          )}
        </>
      ) : null}

      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/favicon/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon/favicon-16x16.png'
      />
      <link
        rel='manifest'
        href='/favicon/site.webmanifest'
      />

      <meta
        name='msapplication-TileColor'
        content='#ffffff'
      />
      <meta
        name='theme-color'
        content='#ffffff'
      ></meta>
    </Head>
  )
}

export default Metadata
