// export const newsletterScript = `(function (m, a, g, n, e, w, s) {
//   m[e] =
//     m[e] ||
//     function () {
//       // If m[e].q is not already an array, it is initialized as one
//       m[e].q = m[e].q || []
//       m[e].q.push([arguments[0], arguments[1], 1 * new Date().getTime()])
//     }

//   w = a.createElement(g)
//   s = a.getElementsByTagName(g)[0]

//   w.async = 1
//   w.src = n
//   s.parentNode.insertBefore(w, s)
// })(
//   // The IIFE is invoked with these arguments
//   window, // m
//   document, // a
//   'script', // g
//   '//m.arcassicura.it/nl/mna1707130800000a5919.js', // n
//   'mna' // e
// )

// // The mna function is called with an object as an argument
// mna('init', {
//   apikey: '171fg267d066d',
//   trackerhostname: 'm.arcassicura.it',
//   trackanonymous: true,
//   enableWebdisplay: true,
//   firePageEvent: true,
//   pageEventParams: {},
// })

// console.info('✅ newsletter script loaded')
// `

export const newsletterScript = `
(function (m, a, g, n, e, w, s) {
m[e] = m[e] || function () {
(m[e].q = m[e].q || []).push([arguments[0], arguments[1], 1 * (new Date()).getTime()]);
};
w = a.createElement(g), s = a.getElementsByTagName(g)[0];
w.async = 1;
w.src = n;
s.parentNode.insertBefore(w, s);
})(window, document, 'script', 'https://m.arcassicura.it/nl/mna1708596000000a5919.js', 'mna');
mna('init', {"apikey":"171fg267d066d","trackerhostname":"m.arcassicura.it","trackanonymous":true,"enableWebdisplay":true,"firePageEvent":true,"pageEventParams":{}});
console.info('✅ newsletter script loaded')
`
