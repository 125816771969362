import { useEffect } from 'react'

import { useDataLoader } from '@hooks/useDataLoader'

import LoaderData from '@/molecules/LoaderData'
import { Footer, Header } from '@/organisms'
import Metadata from '@/organisms/Metadata'

import * as S from './styles'

export default function Layout({
  children,
  metadata,
  headerData = null,
  footerData = null,
  colorScheme = 'white',
}) {
  const { loading } = useDataLoader()

  const isTouch = () => {
    if (
      'ontouchstart' in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      document.body.classList.add('is-touch')
    } else {
      document.body.classList.add('no-touch')
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined') return

    setTimeout(() => {
      isTouch()
    }, 0)
  }, [])

  return (
    <>
      <Metadata {...metadata} />
      {headerData && <Header data={headerData} />}
      <S.Main
        colorScheme={colorScheme}
        id='main-page-content'
      >
        {children}
      </S.Main>
      {footerData && (
        <Footer
          data={footerData}
          id='footer-content'
        />
      )}
      <LoaderData when={loading} />
    </>
  )
}
