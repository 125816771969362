import styled, { css } from 'styled-components'

export const Main = styled.main`
  margin-top: 80px;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    margin-top: 144px;
  `)};

  ${({ theme, colorScheme }) =>
    colorScheme &&
    css`
      background-color: ${theme.colors.surface[colorScheme].background};
    `}
`
